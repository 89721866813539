*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

button {
    cursor: pointer;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', sans-serif;
}

input {
    border: none;
    outline: none;
    font-size: 16px;
}

// UTILITY CLASSES

.flex {
    display: flex;
}

.p-six-four-two {
    padding: 0px 60px;
    @media (max-width: 991.98px) {
        padding: 0px 40px;
    }
    @media (max-width: 480px) {
        padding: 0px 20px;
    }
}

.rich-text-block {
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', sans-serif !important;
    color: #282828 !important;

    img {
        display: block;
        margin: 32px auto;
        max-width: 95%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        background: -webkit-linear-gradient(#e637a8, #e68037);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 32px;
    }

    p,
    li {
        color: #4f4f4f;
        font-size: 18px;
    }

    p {
        margin: 24px 0;
    }

    li {
        list-style: none;
        position: relative;
        margin: 6px 0;

        &:before {
            content: '';
            background: url('/assets/icons/check-orange.svg');
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            left: -32px;
            position: absolute;
            top: 0px;
        }
    }
}

.research-rich-text-block {
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', sans-serif !important;
    color: #282828 !important;

    h3,
    h4,
    h5,
    h6 {
        background: -webkit-linear-gradient(#e637a8, #e68037);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    img {
        display: block;
        margin: 32px auto;
        max-width: 95%;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}

.gradient-header {
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', sans-serif !important;
    color: #282828 !important;

    h3,
    h4,
    h5,
    h6 {
        background: -webkit-linear-gradient(#e637a8, #e68037);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.black-header {
    h4 {
        background: -webkit-linear-gradient(#4f4f4f, #4f4f4f);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.testimonial-carousel {
    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        & > div {
            height: 100%;

            & > div {
                height: 100%;
            }
        }
    }

    .slick-dots li {
        width: 9px;
        height: 9px;
        margin: 0 4px;
        padding: 0;
        transition: all 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);

        button {
            width: 9px;
            height: 9px;
            padding: 0;
            transition: all 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);

            &:before {
                content: ' ';
                width: 9px;
                height: 9px;
                opacity: 0.7;
                background: #b4b4b4;
                border-radius: 50%;
                transition: width 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53),
                opacity 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53),
                border-radius 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
            }
        }

        &.slick-active {
            width: 37px;

            button {
                width: 37px;

                &:before {
                    width: 37px;
                    opacity: 1;
                    background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);
                    border-radius: 20px;
                }
            }
        }
    }

    @media (max-width: 576px) {
        .slick-dots {
            bottom: 10px;
        }
    }
}

.custom-radios-group {
    display: flex;
    flex-wrap: wrap;

    .custom-radio {
        cursor: pointer;
        position: relative;
        padding-left: 35px;
        margin: 12px 38px 12px 0;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #ffffff;
        //width: 102px;

        &:last-child {
            margin-right: 0;
        }

        &:before {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #121651;
            content: '';
            border: 2px solid white;
            background: transparent;
            position: absolute;
            left: 0px;
            top: 0;
        }

        &:after {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #121651;
            content: '';
            position: absolute;
            left: 4px;
            top: 4px;
        }

        &.radio-checked:after {
            background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);
        }
    }

    @media (max-width: 570px) {
        .custom-radio {
            margin: 10px 0;
            padding-left: 32px;
            width: 50%;
        }
    }

    &.radios-black {
        .custom-radio {
            color: #121651;

            &:before {
                border: 2px solid #121651
            }

            &:after {
                background: transparent;
            }

            &.radio-checked:after {
                background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);
            }
        }
    }
}

.fcp {
    .fcp__mobile {
        .submit-btn {
            width: 100%;
        }
    }
}

.rich-text-block2 {
    color: #4f4f4f;
    font-family: Inter, serif;
    font-size: 18px;

    ul {
        margin: 24px 0;
        list-style-image: url('/assets/icons/red-bullet.svg');

        li {
            margin: 12px 0;
            padding-left: 8px;
        }
    }
}

.faq-accordion {
    .rc-collapse {
        background: transparent;
        border: none;

        & > .rc-collapse-item {
            border-top: none;
            border-bottom: 2px solid #e8e8e8;
            padding: 28px 0;

            & > .rc-collapse-header {
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: flex-start;
                font-weight: 800;
                font-size: 32px;
                line-height: 38px;
                color: #121651;
                padding: 0;

                img {
                    width: 34px;
                    height: 34px;
                    margin: 0 30px;
                }
            }

            .rc-collapse-content {
                display: block !important;
                background: transparent;
                padding: 0;
                max-height: 0;
                transition: all 0.3s ease-in-out;

                & > .rc-collapse-content-box {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 25px;
                    color: #4f4f4f;
                    margin: 18px 94px 0 0;
                }

                &.rc-collapse-content-active {
                    max-height: 500px;
                }

                &.rc-collapse-content-hidden {
                    max-height: 0;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .rc-collapse {
            & > .rc-collapse-item {
                padding: 20px 0;

                & > .rc-collapse-header {
                    font-size: 26px;

                    img {
                        width: 26px;
                        height: 26px;
                        margin: 0 20px;
                    }
                }

                .rc-collapse-content {
                    & > .rc-collapse-content-box {
                        font-size: 16px;
                        margin: 18px 66px 0 0;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        .rc-collapse {
            & > .rc-collapse-item {
                & > .rc-collapse-header {
                    font-size: 20px;
                    line-height: 26px;

                    img {
                        margin: 0 0 0 20px;
                    }
                }

                .rc-collapse-content {
                    & > .rc-collapse-content-box {
                        margin: 18px 0 0 0;
                    }
                }
            }
        }
    }
}

#footer {
    .footer--wrapper {
        .footer--row {
            .footer--info-column {
                .footer--info-item {
                    a,
                    span {
                        font-weight: 800;
                        font-size: 18px;
                        line-height: 25px;
                        color: #ffffff;
                        opacity: 0.7;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    a {
                        text-decoration-line: underline;
                    }
                }
            }
        }
    }
}

